<template>
  <div style="background: #FFFFFF;padding: 20px 16px 80px 16px">
    <div class="top" style="display: flex;justify-content: space-between;align-items: center;margin-bottom: 10px">
      <i @click="$router.go(-1);$store.commit('changeAlterCoverConfigOrCallback',0)" style="font-size: 24px" class="el-icon-arrow-left"></i>
      <div>{{isAddMartin==1 ? $t('robot.add_martin') : isAddMartin==2 ? $t('robot.reset_martin') : $t('robot.set_trade')}}</div>
      <img v-if="change_url=='robot'" src="../../../assets/img/robot/binance_sign.svg">
      <img v-else-if="change_url=='okex'" src="../../../assets/img/robot/okex_sign.svg">
    </div>
    <Detail_param @closeDetail="closeDetail" :coverConfig="coverConfig" :coverCallback="coverCallback"  ref="detail_paramRef" @openCoverDialog="openCoverDialog" @changeCoverConfig="changeCoverConfig"></Detail_param>
    <div class="footer">
      <div @click="$router.go(-1);$store.commit('changeAlterCoverConfigOrCallback',0)">{{isAddMartin==1 ? $t('robot.cancel') : isAddMartin==2 ? $t('robot.cancel_reset') : $t('robot.cancel_edit')}}</div>
      <el-button :loading="isSuccess" @click="handleCreateMartin" style="font-size:16px;color:#52C41A;border: 0;border-radius: 0">{{isAddMartin==1 ? $t('robot.start_') : isAddMartin==2 ? $t('robot.restart_') : $t('robot.save_changes')}}</el-button>
    </div>
    <CoverConfig @sentData="sentData" @openDetailDialog="MartinAddDialog=true" ref="coverConfigRef"></CoverConfig>
  </div>
</template>

<script>
import Detail_param from "./Detail_param";
import CoverConfig from "./CoverConfig";
import {screenSize} from "../../../utils/tools";
import {change_url} from "../../../server/robot";
export default {
  name: "Martin_add_sm",
  data(){
    return{
      isAddMartin:1,
      change_url:'robot',
      coverConfig:[],
      coverCallback:[],
      isSuccess:false
    }
  },
  components:{Detail_param,CoverConfig},
  created() {

  },
  mounted() {
    this.$refs.detail_paramRef.getDealCoins()
    this.change_url=sessionStorage.getItem('robotType')==0 ? 'robot' :'okex'
    if (this.$store.state.addAlterMartin==1 && sessionStorage.getItem('martin')==1) {
      this.isAddMartin = 1
      this.$refs.detail_paramRef.change_url=sessionStorage.getItem('robotType')==0 ? 'robot' :'okex'
    }
    else {
      this.$refs.detail_paramRef.change_url=sessionStorage.getItem('robotType')==0 ? 'robot' :'okex'
      this.$store.commit('addAlterMartin',JSON.parse(sessionStorage.getItem('alertDetails')))
      this.coverConfig=JSON.parse(JSON.stringify(this.$store.state.addAlterMartin.replenishment_conf_new))
      this.coverCallback=JSON.parse(JSON.stringify(this.$store.state.addAlterMartin.replenishment_callback_new))
      this.$refs.detail_paramRef.recoverAlterParam()
      this.$refs.coverConfigRef.recoverAlterParam()
      if (sessionStorage.getItem('martin')==2) this.isAddMartin = 2
      else this.isAddMartin=3
    }
  },
  methods:{
    //關閉參數詳情
    closeDetail(e=0){
      this.MartinAddDialog=false
      this.$refs.detail_paramRef.showDetail=false
      this.$refs.detail_paramRef.policyTypeValue=''
      this.$refs.detail_paramRef.trading_end_value=''
      this.$store.commit('changeAlterCoverConfigOrCallback',0)
      this.$router.go(-1)
      this.isSuccess=false
    },
    //打开补仓配置或者补仓回调弹框
    openCoverDialog(e,detailParams,policyTypeValue){
      this.$refs.coverConfigRef.saveCoverNeedData(detailParams,policyTypeValue)
      if (e==1) this.$refs.coverConfigRef.coverConfigDialog=true
      if (e==2) this.$refs.coverConfigRef.coverCallbackDialog=true
      this.MartinAddDialog=false
    },
    //用户改变参数导致补仓配置变化为默认配置
    changeCoverConfig(policyTypeValue,trendPeriod,orderNumber){
      this.$refs.coverConfigRef.changeCoverConfig(policyTypeValue,trendPeriod,orderNumber)
    },
    //選擇打開對話框OR手機端頁面
    // handleOpenType(type){
    //   if (screenSize(900))  this.MartinAddDialog=true
    //   else {
    //     this.$router.push('/robot/martin_add')
    //   }
    // },

    //  生成策略
    handleCreateMartin(){
      this.$refs.detail_paramRef.createMartin()
    },
    //获取补仓配置发送过来的数据
    sentData(coverConfig,coverCallback){
      this.coverConfig=JSON.parse(JSON.stringify(coverConfig))
      this.coverCallback=JSON.parse(JSON.stringify(coverCallback))
    }
  }

}
</script>

<style scoped>
.footer{
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  background: #FFFFFF;
  height: 56px;
  display: flex;
  padding: 0 16px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 4px 0px rgba(0, 0, 0, 0.05), 0px 12px 48px 16px rgba(0, 0, 0, 0.03);
}
.footer>div:first-child{
  flex: 1;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #C0C4C8;
  margin-right: 10px;
  font-size: 14px;
  color: #72777E;
  text-align: center;
  line-height: 40px;
  box-sizing: border-box;
}
.footer> .el-button{
  flex: 1;
  height: 40px;
  background: #52C41A;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 500;
  /*box-sizing: border-box;*/
  color: #FFFFFF !important;
  /*text-align: center;*/
  padding: 0;
  /*line-height: 40px;*/
}
</style>
